@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
  background: #19191A !important;
}

.footer {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  /* padding: 24px 32px; */

  @media(max-width: 767px){
    padding: 0 20px;
    gap: 15px;
  }
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;

  & li{
    margin-bottom: 10px;
  }
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  /* font-size: 16px;
  line-height: 24px; */
  color: white;

  @media(max-width: 767px){
    font-size: 30px;
    font-weight: 600;
    line-height: 41.6px;
    text-align: left;
  }
}
.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}
.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  font-family: Montserrat;
  display: inline-block;
  /* font-size: 12px; */
  line-height: 16px;
  /* color: var(--colorGrey500); */
  color: white;
  /* margin-top: 24px; */

  @media(max-width: 767px){
    text-align: center;
    display: block;
    margin-bottom: -15px;
    font-weight: 400;
    letter-spacing: 0.02em;
  }
}

.icons {
  /* margin-top: 24px; */

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}
.logoWrapper {
  max-width: 370px;
}
.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    gap: unset;
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . grid'
      'details . grid'
      'details . .'
      'details . .';
  }
  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }
  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}


.bottomTextWrapper{
  display: flex;
  align-items: center;
  gap: 2em;

  & .supportEmail{
    margin: 0;
    display: flex;
    gap: 5px;
    align-items: center;

    & span{
      color: white;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 42.84px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }

  @media(max-width: 767px){
    flex-direction: column-reverse;
    gap: 1em;

    & .supportEmail{
      margin: 0;
      align-self: baseline;
  
      & span{
        font-size: 14px;
        font-weight: 400;
      }
    }
  }  
}