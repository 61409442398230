.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.title{
  font-weight: 600;
  @media(min-width: 1200px){
    width: 75%;
  }

  @media(max-width: 767px){
    font-size: 38px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
  }
}

.header{
  padding: 0 11em;

  @media(max-width: 767px){
    padding: 0 15px;
  }
}

.description{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin: 5px 0 27px;

  @media(max-width: 767px){
    font-size: 18px;
    font-weight: 500;
    line-height: 21.5px;
    text-align: left;
    text-wrap: unset;
  }
}

.ctaButton{
  background: #9238FF;
  text-decoration: none !important;
  padding: 12px 28px;
  border-radius: 22px;
}

.typewriterWrapper{
  display: inline;
  & >div{
    display: inline;
  }

  @media(max-width: 767px){
    & >div{
      /* display: none; */
    }

    & .mobileText{
      display: inline;
      color: #9238FF;
      font-size: 38px;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
    }
  }
  @media(min-width: 767px){
    & .mobileText{
      display: none;
    }
  }
}

.mobileSpace{
  @media(max-width: 767px){
    display: none;
  }
}

.typewriter{
  color: #9238FF;
}